@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:'Montserrat',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.about_container {
  position: relative;
  width: 1441px;
  height: 324px;
}

.main_container {

  width: 100vw;
  height: 100%;

}

.pagination {
  display: flex;
}

.container_pos  {
  display: flex;
  flex-direction: column;
  width: 1444px;
  
}

.pagination-btn {
  display: flex;
  justify-content: space-between;
}

.CompanySlider {
  display: flex; 
  flex-direction: column;
  width:1444px;
  position:relative
}


.slider {
 position:relative ;
 display:  flex;
 justify-content: space-between;
 border-radius: 48px;
  margin: 0 2rem;
  justify-content: center;
  background-color: #DDD;
  height: 35rem;
  width: 1377px;
}

.page-item {
  display: inline-block;
  margin-right: 5px;
}

.page-link {
  padding: 10px;
  border-radius: 5px;
  color: #000; /* Цвет цифр */
  text-decoration: none;
}

.page-link:hover {
  background-color: #089449; /* Цвет фона при наведении на цифры */
  color: #fff;
}

/* Стили для стрелок */
.pagination li {
  /* Стили для элементов списка (цифры и стрелки) */
}

.pagination li a {
  /* Стили для ссылок внутри элементов списка */
  background-color: #F3F3F3; /* Цвет фона для стрелок */
  color: #000; /* Цвет текста для стрелок */
  border-radius: 56px;

}

.pagination li a:hover {
  background-color: #089449; /* Цвет фона при наведении на стрелки */
  color: #fff;
}
/* styles.css (или ваш файл со стилями) */

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  outline: none;
  borderRadius: 8px;
  maxWidth: 80%;
  max-height: 80%; /* Ограничение высоты модального окна */
  overflow-y: auto; /* Включение вертикальной прокрутки, если контент превышает высоту */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Запрет прокрутки за пределами модального окна */
}








.registration-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  outline: none;
  borderRadius: 8px;
  width: 600px;
  height: 653px;
  overflow: hidden;
}

.registration-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.password-input {
  position: relative;
}

.password-input button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-options {
  margin-top: 20px;
  text-align: center;
}

.social-login button {
  margin: 10px;
}



.my-container {
  display: flex;
  flex-wrap: wrap;
}
.slider_img {
  display:   flex;
   justify-content: flex-end ;
  align-items: center;
  }




  @media (max-width: 768px) {
    .my-container {
      display: block;
      flex-wrap: nowrap;
      margin: 0 auto;
    }
    .slider{
      display: flex;
      flex-direction: column;
      width: 768px;
      height: 57rem;
      margin:  0 auto;
    }
    .slider_img {
      display:   flex;
    justify-content: flex-start;
      align-items: center;
      }
      .main_container {
        width: 46rem;
      }
      .w-full {
        width: 100%;
      }
      .my-container  {
        display: block;
        flex-wrap: nowrap;
        margin: 0 auto;
      }
      .container_pos {
        width: 768px;
       
        margin:  0 auto;
      }
      .CompanySlider {
        width: 768px;
      }

      .footer_container {
        flex-direction: column;
      }
      .about_container {
        width: 48rem;
        margin: 0;
      }
  }
  
  @media (max-width: 360px) {
  
  
    .pagination-btn {
      display: flex;
      flex-direction: column;
  
    }
  }



  .footer_container {
   display: flex;
   justify-content:   space-between;
   margin-left: 9rem;
   margin-bottom: 4.5rem;
  }